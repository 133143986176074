import React from "react"

import "./Footer.scss"
import { langVersion } from "../../accessors"
import { FOOTER_COL1, FOOTER_COL2, FOOTER_COL3 } from "../../constans"
import {
  FOOTER_SOCIALS1,
  FOOTER_SOCIALS2,
  FOOTER_SOCIALS3,
} from "../../constans"
import FooterBrand from "./FooterBrand"
import FooterParagraph from "./FooterParagraph"
import FooterLink from "./FooterLink"
import FooterSocials from "./FooterSocials"
import AdmLog from "./AdmLog"
import BackTopBtn from "../Buttons/BackTopBtn"
import facebook from "../../images/icons/facebook.svg"
import instagram from "../../images/icons/instagram.svg"
import twitter from "../../images/icons/twitter.svg"

const Footer = ({ project }) => {
  return (
    <footer className="page__footer">
      <section className="footer__info">
        <article className="article">
          <FooterBrand link={langVersion("/", "/en/")} />
          <FooterParagraph
            text={langVersion(FOOTER_COL1[0][0], FOOTER_COL1[0][1])}
          />
        </article>
        <article className="article">
          <FooterLink
            link={langVersion("/contact", "/en/contact")}
            text={langVersion(FOOTER_COL2[0][0], FOOTER_COL2[0][1])}
          />
          <FooterLink
            link={langVersion("/about", "/en/about")}
            text={langVersion(FOOTER_COL2[1][0], FOOTER_COL2[1][1])}
          />
        </article>
        <article className="article">
          <FooterParagraph
            text={langVersion(FOOTER_COL3[0][0], FOOTER_COL3[0][1])}
          />
          <div className="footer__socials">
            <FooterSocials
              link={FOOTER_SOCIALS1[0]}
              title={langVersion(
                `Idź do profilu ${FOOTER_SOCIALS1[1]}`,
                `Go to ${FOOTER_SOCIALS1[1]} profile`
              )}
              src={facebook}
              alt={`Logo of ${FOOTER_SOCIALS1[1]}`}
            />
            <FooterSocials
              link={FOOTER_SOCIALS2[0]}
              title={langVersion(
                `Idź do profilu ${FOOTER_SOCIALS2[1]}`,
                `Go to ${FOOTER_SOCIALS2[1]} profile`
              )}
              src={instagram}
              alt={`Logo of ${FOOTER_SOCIALS2[1]}`}
            />
            <FooterSocials
              link={FOOTER_SOCIALS3[0]}
              title={langVersion(
                `Idź do profilu ${FOOTER_SOCIALS3[1]}`,
                `Go to ${FOOTER_SOCIALS3[1]} profile`
              )}
              src={twitter}
              alt={`Logo of ${FOOTER_SOCIALS3[1]}`}
            />
          </div>
        </article>
      </section>

      <section className="footer__authors">
        <FooterParagraph
          text={`${project ? project : "2020"}, by Paweł Nieczuja-Ostrowski`}
        />
        <AdmLog />
      </section>

      <BackTopBtn />
    </footer>
  )
}

export default Footer
