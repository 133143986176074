import React from "react"
import {
  EDUCATION_2020,
  EDUCATION_2021,
} from "../../content/education/education.const"
import { langVersion } from "../../accessors"
import ChapterLinkInner from "../Index/ChapterLinkInner"
import "./EducationChapters.scss"

const EducationChapters = () => {
  return (
    <section id="chapters" className="education__chapters">
      <ChapterLinkInner
        url={langVersion("/education/2020", "/education/2020")}
        title={langVersion(EDUCATION_2020.title.pl, EDUCATION_2020.title.en)}
        text={langVersion(
          EDUCATION_2020.description.pl,
          EDUCATION_2020.description.en
        )}
        logo={[EDUCATION_2020.logo]}
        img={EDUCATION_2020.bgPhoto}
      />
      <ChapterLinkInner
        url={langVersion("/education/2021", "/education/2021")}
        title={langVersion(EDUCATION_2021.title.pl, EDUCATION_2021.title.en)}
        text={langVersion(
          EDUCATION_2021.description.pl,
          EDUCATION_2021.description.en
        )}
        logo={[EDUCATION_2021.logo]}
        img={EDUCATION_2021.bgPhoto}
      />
    </section>
  )
}

export default EducationChapters
