import React from "react"

import Fest15MainTemplate from "../../../components/FestPages/15Festival/15FestMainTemplate"
import Fest15Hero from "../../../components/FestPages/15Festival/15FestHero"
import FestSection from "../../../components/FestPages/FestSection"
const welcome = require("../../../content/en/theatre-literature-management/welcome.json")
const program = require("../../../content/en/theatre-literature-management/program.json")

const IndexPage = () => (
  <Fest15MainTemplate>
    <Fest15Hero />
    <FestSection data={welcome} />
    <FestSection data={program} />
  </Fest15MainTemplate>
)

export default IndexPage
