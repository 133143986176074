import React from "react"

import "../../../../components/FestPages/FestTitles.scss"
import "../../../../components/FestPages/FestContainers.scss"
import "../../../../components/FestPages/FestWelcome.scss"
import "../../../../components/FestPages/FestProgram.scss"
import "../../../../components/FestPages/FestCooperation.scss"
import Fest14MainTemplate from "../../../../components/FestPages/14Festival/14FestMainTemplate"
import Fest14Hero from "../../../../components/FestPages/14Festival/14FestHero"
import FestSection from "../../../../components/FestPages/FestSection"
const welcome = require("../../../../content/en/festivals/14-festival/welcome.json")
const program = require("../../../../content/en/festivals/14-festival/program.json")

const IndexPage = () => (
  <Fest14MainTemplate>
    <Fest14Hero />
    <FestSection data={welcome} />
    <FestSection data={program} />
  </Fest14MainTemplate>
)

export default IndexPage
