import React, { useState } from "react"
import { Link } from "gatsby"
//import { langVersion } from "../../accessors";
import "./Crossroads.scss"
import "../../styles/components/_containers.scss"

const Crossroad = ({ elems }) => {
  //console.log(elems);
  const [curtain, activeCurtain] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ])

  const active = " chapter__curtain--active"

  const random = index => {
    return index % 2 !== 1 ? true : false
  }

  const nmbr = nmbr => <h2 className="crossroad-box__title">{nmbr}</h2>
  const title = title => <h3 className="crossroad-box__subtitle">{title}</h3>
  const addElems =
    elems !== undefined &&
    elems.map((item, index) => (
      <Link
        key={"crossroad" + index}
        to={`/education/${item.project}/${item.link}`}
        className="crossroad__box"
        style={{ backgroundImage: `url("/images/photos/min/${item.photo}")` }}
      >
        {nmbr(item.nmbr)}
        {title(item.title)}
        <div
          className={"chapter__curtain" + (random(index) ? active : "")}
        ></div>
        <div className="chapter__shadow"></div>
      </Link>
    ))

  return <section className="section crossroad">{addElems}</section>
}

export default Crossroad

//array 15 elems in elems
//project, nmbr, title, photo, link
