import React from "react"
import { Link } from "gatsby"
import logo from "../../images/icons/between_2020_brand_white.png"
import "./HeaderBrand.scss"

const HeaderBrand = (props) => (
  <Link className="brand" to={props.link}>
    <img className="brand__logo" src={logo} alt="Logo of Between.Pomiedzy"></img>
  </Link>
)

export default HeaderBrand