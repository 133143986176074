import React from "react"
import { keyGenerate } from "../accessors"
import "../styles/components/_texts.scss"
import "../styles/components/_containers.scss"
import "../styles/components/_titles.scss"
import LogoImg from "../components/Images/LogoImg"
import BookLink from "../components/Images/BookLink"
import DocLink from "../components/Docs/DocLink"
import ImgFigure from "../components/Images/ImgFigure"

const Elems = ({ data }) => {
  const addElems = collection => {
    return collection.map((item, index) => {
      if (item.article !== undefined)
        return (
          <article className="article" key={item.article.toString()}>
            {addElems(item.article)}
          </article>
        )
      if (item.articleTitle !== undefined)
        return (
          <h3 className="article__title" key={item.articleTitle.toString()}>
            {typeof item.articleTitle === "string"
              ? item.articleTitle
              : addElems(item.articleTitle)}
          </h3>
        )
      if (item.sectionTitle !== undefined)
        return (
          <h2 className="section__title" key={item.sectionTitle.toString()}>
            {typeof item.sectionTitle === "string"
              ? item.sectionTitle
              : addElems(item.sectionTitle)}
          </h2>
        )
      if (item.prgh !== undefined) {
        return (
          <p className="article__text" key={item.prgh.toString()}>
            {typeof item.prgh === "string" ? item.prgh : addElems(item.prgh)}
          </p>
        )
      }
      if (item.smallPrgh !== undefined) {
        return (
          <p className="small__text" key={item.smallPrgh.toString()}>
            {typeof item.smallPrgh === "string"
              ? item.smallPrgh
              : addElems(item.smallPrgh)}
          </p>
        )
      }
      if (item.italic !== undefined)
        return (
          <span className="text--italic" key={item.italic.toString()}>
            {item.italic}
          </span>
        )
      if (item.bold !== undefined)
        return (
          <span className="text--bold" key={item.bold.toString()}>
            {item.bold}
          </span>
        )
      if (item.boldItalic !== undefined)
        return (
          <span className="text--bold-italic" key={item.boldItalic.toString()}>
            {item.boldItalic}
          </span>
        )
      if (item.a !== undefined)
        return (
          <a
            className="text--link"
            href={item.a}
            key={item.a.toString()}
            title={"Link to " + item.a}
            target={item.target}
          >
            {item.text !== undefined ? item.text : item.a}
          </a>
        )
      if (item.mail !== undefined)
        return (
          <a
            className="text--link"
            href={"mailto:" + item.mail}
            key={item.mail.toString()}
            title={"Mail to " + item.mail}
          >
            {item.text !== undefined ? item.text : item.mail}
          </a>
        )
      if (item.text !== undefined) return item.text
      if (item.logoImg !== undefined)
        return (
          <LogoImg key={item.logoImg[0].toString()} elems={item.logoImg[0]} />
        )
      if (item.linkBook !== undefined)
        return (
          <BookLink key={item.linkBook.toString()} collection={item.linkBook} />
        )
      if (item.docLink !== undefined)
        return (
          <DocLink
            key={item.docLink.toString()}
            collection={item.docLink}
            location={item.location}
          />
        )
      if (item.figures !== undefined)
        return <ImgFigure key={keyGenerate(item.figures, index)} data={item} />
      if (item.br !== undefined) return <br />
    })
  }

  return <section className="section">{addElems(data)}</section>
}

export default Elems
