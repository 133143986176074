import React from "react"
import { Link } from "gatsby"
import { langVersion } from "../../accessors";

import "./GoForMore.scss"

const GoForMore = () => (
  <section className="go-for-more">
    <Link className="go-down__box" to="#chapters">
      <h3 className="go-down__title">{langVersion('zobacz', 'see')}</h3>
      <button className="go-down__btn"></button>
      <h3 className="go-down__title">{langVersion('więcej', 'more')}</h3>
    </Link>
  </section>
)

export default GoForMore