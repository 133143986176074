import React from "react"
import Head from "../../../../components/Head"

import Fest12Header from "../../../../components/FestPages/12FestHeader"
import FestSection from "../../../../components/FestPages/FestSection"
import FestFooter from "../../../../components/FestPages/FestFooter"
const about = require("../../../../content/en/festivals/12-festival/about/about.json")
const socials = require("../../../../content/en/festivals/12-festival/socials.json")
const footer = require("../../../../content/en/festivals/12-festival/footer.json")
const PageTitle = "About"

const IndexPage = () => (
  <>
    <Head pageTitle={"Between.Pomiędzy | 12. Festival | " + PageTitle} />
    <Fest12Header title={PageTitle} />

    <main className="main">
      <FestSection data={about} />
    </main>

    <FestFooter socials={socials} footer={footer} />
  </>
)

export default IndexPage
