import React from "react"
import "../components/FestPages/FestContainers.scss"
import Fest12Header from "../components/FestPages/12FestHeader"
import FestSection from "../components/FestPages/FestSection"
import FestFooter from "../components/FestPages/FestFooter"

const Fest12Page = ({ pageContext: { data } }) => {
  const chapter = require(`../content/${
    data.lang !== undefined ? data.lang : ""
  }festivals/12-festival/${data.folder}/${data.page}.json`)
  const socials = require(`../content/${
    data.lang !== undefined ? data.lang : ""
  }festivals/12-festival/socials.json`)
  const footer = require(`../content/${
    data.lang !== undefined ? data.lang : ""
  }festivals/12-festival/footer.json`)
  return (
    <>
      <Fest12Header page={data.page} />
      <main className="fest-main">
        <FestSection data={chapter} />
      </main>
      <FestFooter socials={socials} footer={footer} />
    </>
  )
}

export default Fest12Page
