import React from "react";
import { Link } from "gatsby";
import logo from "../../images/icons/between_2020_white.png"

import "./FooterIcons.scss"

const FooterBrand = ({ link }) => (
  <Link className="footer-brand" to={link}>
    <img className="footer__logo" src={logo} alt="Logo of Between.Pomiedzy"></img>
  </Link>
)

export default FooterBrand