import React from "react"
import "./IndexHero.scss"
import "./HeroTitle.scss"
import { langVersion } from "../../accessors"
import { HERO_TITLE, HERO_TEXT, HERO_BTN4, HERO_BTN5 } from "../../constans"
import HeroBtn from "./HeroBtn"

const special = spec => (spec !== undefined ? spec : "")

const IndexHero = () => (
  <section className="hero">
    <h1 className="page__title">{langVersion(HERO_TITLE[0], HERO_TITLE[1])}</h1>
    <p className="page__text">{langVersion(HERO_TEXT[0], HERO_TEXT[1])}</p>
    <article className="page__article">
      <HeroBtn
        link={langVersion(`/${HERO_BTN4[2]}`, `/en/${HERO_BTN4[2]}`)}
        text={langVersion(HERO_BTN4[0], HERO_BTN4[1])}
        special={special(HERO_BTN4[3])}
      />
      <div className="page__line"></div>
      <HeroBtn
        link={langVersion(`/${HERO_BTN5[2]}`, `/${HERO_BTN5[2]}`)}
        text={langVersion(HERO_BTN5[0], HERO_BTN5[1])}
        special={special(HERO_BTN5[3])}
      />
    </article>
    <div className="triangle"></div>
    <div className="triangle"></div>
  </section>
)

export default IndexHero
