import React from "react"

import "../../../../components/FestPages/FestTitles.scss"
import "../../../../components/FestPages/FestContainers.scss"
import "../../../../components/FestPages/FestWelcome.scss"
import "../../../../components/FestPages/FestProgram.scss"
import "../../../../components/FestPages/FestCooperation.scss"
import Fest13MainTemplate from "../../../../components/FestPages/13Festival/13FestMainTemplate"
import Fest13Hero from "../../../../components/FestPages/13Festival/13FestHero"
import FestSection from "../../../../components/FestPages/FestSection"
const welcome = require("../../../../content/en/festivals/13-festival/welcome.json")
// const events = require("../../../../content/en/festivals/13-festival/events.json")
const program = require("../../../../content/en/festivals/13-festival/program.json")

const IndexPage = () => (
  <Fest13MainTemplate>
    <Fest13Hero />
    <FestSection data={welcome} />
    <FestSection data={program} />
  </Fest13MainTemplate>
)

export default IndexPage
