import React from "react"
import "./FestTitles.scss"
import "./KosmoHero.scss"
import { langVersion } from "../../accessors"
import FestCosmos from "./FestCosmos"

const KosmoHero = () => {
  return (
    <section id="kosmopolis-hero" className="fest__hero">
      <FestCosmos />
      <article className="fest__jumbotron">
        <article className="fest__hero-box fest__hero-box--left">
          {typeof window !== `undefined` ? (
            <>
              <h3 className="fest__article-title--old">
                {langVersion(
                  "XI Festiwal Literatury i Teatru",
                  "XI Festival of Literature and Theatre"
                )}
              </h3>
              <h1 className="fest__page-title--old">Kosmopolis.2020</h1>
              <h2 className="fest__section-title--old">
                {langVersion("Between.Pomiedzy", "Between.Pomiedzy")}
              </h2>
            </>
          ) : (
            <div>Content is loading...</div>
          )}
        </article>
        <article className="fest__hero-box fest__hero-box--right">
          <p className="fest__article-text">
            {langVersion(
              "Kosmopolis 2020 to festiwal dwujęzyczny. Część materiałów prezentujemy w języku polskim. Część materiałów w języku angielskim. Gdzie to możliwe zapewniamy obie wersje językowe.",
              "This is a bilingual festival. Some materials are in English. Others in Polish. We’ve tried to provide both versions wherever possible."
            )}
          </p>
          <a
            className="fest__button-link"
            href="#fest__program"
            title={langVersion("Idź do programu", "Go to Program section")}
          >
            <button className="fest__button-main">
              {langVersion("program", "programme")}
            </button>
          </a>
        </article>
      </article>
    </section>
  )
}

export default KosmoHero
