import React from "react";
import LogInBox from "../components/Adm/LogInBox";
import ProtectedMain from "../components/Adm/ProtectedMain";
import AdmHeader from "../components/Adm/AdmHeader";
import { getUser } from "../auth";

import "../components/Adm/Login.scss";



const isAuthenticated = () => {
  return (getUser() !== undefined && getUser() !== null) ? true : false
}

const Login = () => (

  <main className="adm-main">
    {isAuthenticated() ? <AdmHeader profile="private"/> : <AdmHeader profile="public"/>}
    {isAuthenticated() ? <ProtectedMain /> : <LogInBox />}
  </main>
)




export default Login;