import React from "react"
import ErrorInfo from "../../components/Service/ErrorInfo"

const NotFoundPage = () => (
  <div id="error-page">
    <ErrorInfo />
  </div>
)

export default NotFoundPage
