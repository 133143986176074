import React from "react"

import "./IndexChapters.scss"
import {
  INDEX_CHAPT1,
  INDEX_CHAPT2,
  INDEX_CHAPT3,
  INDEX_CHAPT4,
  INDEX_CHAPT5,
} from "../../constans"
import {
  INDEX_CHAPT1_DESCR,
  INDEX_CHAPT2_DESCR,
  INDEX_CHAPT3_DESCR,
  INDEX_CHAPT4_DESCR,
  INDEX_CHAPT5_DESCR,
} from "../../constans"
import bgPhoto1 from "../../images/photos/hero-small.jpg"
import bgPhoto2 from "../../images/photos/B1_mozaic.jpg"
import bgPhoto3 from "../../images/photos/A.7_Between_Wydawnictwa.jpg"
import bgPhoto4 from "../../images/photos/A3_2_beckett.jpg"
import bgPhoto5 from "../../images/photos/0_zdjecie_glowne_2025_small.jpg"
import { langVersion } from "../../accessors"
import ChapterLinkInner from "./ChapterLinkInner"

const IndexChapters = () => {
  return (
    <section id="chapters" className="chapters">
      <ChapterLinkInner
        url={langVersion("/theatre-literature-management", "/en/theatre-literature-management")}
        title={langVersion(INDEX_CHAPT5[0], INDEX_CHAPT5[1])}
        text={langVersion(INDEX_CHAPT5_DESCR[0], INDEX_CHAPT5_DESCR[1])}
        img={bgPhoto5}
      />
      <ChapterLinkInner
        url={langVersion("/festivals", "/en/festivals")}
        title={langVersion(INDEX_CHAPT1[0], INDEX_CHAPT1[1])}
        text={langVersion(INDEX_CHAPT1_DESCR[0], INDEX_CHAPT1_DESCR[1])}
        img={bgPhoto1}
      />
      <ChapterLinkInner
        url={langVersion(
          "/festivals/kosmopolis/between-research/chapter-7",
          "/en/festivals/kosmopolis/between-research/chapter-7"
        )}
        title={langVersion(INDEX_CHAPT3[0], INDEX_CHAPT3[1])}
        text={langVersion(INDEX_CHAPT3_DESCR[0], INDEX_CHAPT3_DESCR[1])}
        img={bgPhoto3}
      />
      <ChapterLinkInner
        url={langVersion("/research", "/en/research")}
        title={langVersion(INDEX_CHAPT4[0], INDEX_CHAPT4[1])}
        text={langVersion(INDEX_CHAPT4_DESCR[0], INDEX_CHAPT4_DESCR[1])}
        img={bgPhoto4}
      />
      <ChapterLinkInner
        url={langVersion("/education", "/en/education")}
        title={langVersion(INDEX_CHAPT2[0], INDEX_CHAPT2[1])}
        text={langVersion(INDEX_CHAPT2_DESCR[0], INDEX_CHAPT2_DESCR[1])}
        img={bgPhoto2}
      />
    </section>
  )
}

export default IndexChapters
