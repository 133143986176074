import React from "react"
import { Helmet } from "react-helmet"
import favicon from "../images/icons/between FAVICON LOGO.png"

const Head = ({ pageTitle }) => (
  <>
    <Helmet>
      <title>{pageTitle}</title>
      {/* <link rel="icon"
          type="image/png"
          href={favicon}></link> */}
      <link rel="alternate" hreflang="pl" href="https://between.org.pl/" />
      <link rel="alternate" hreflang="en" href="https://between.org.pl/en/" />
    </Helmet>
  </>
)

export default Head
