import React from "react"
import Head from "../components/Head"

import Header from "../components/Headers/Header"
import PageHeader from "../components/PageHeaders/PageHeader"
import Elems from "../templates/elems"
import Footer from "../components/Footers/Footer"
import { PAGE_TITLE } from "../content/about/constans"
import { data } from "../content/about/about.json"

const PageTitle = PAGE_TITLE[0]

const IndexPage = () => (
  <>
    <Head pageTitle={"Between.Pomiędzy | " + PageTitle} />
    <Header title={PageTitle} />

    <main className="main">
      <PageHeader pageTitle={PageTitle} />
      <Elems data={data} />
    </main>

    <Footer />
  </>
)

export default IndexPage
