import React from "react"
import { keyGenerate, checkUrl, isBrowser } from "../../accessors"
import FestParagraph from "./FestParagraph"
import FestSubtitle from "./FestSubtitle"
import FestArticleTitle from "./FestArticleTitle"
import FestLinks from "./FestLinks"
import FestFigure from "./FestFigure"
import DocLink from "../Docs/DocLink"
import "./FestContainers.scss"

const FestArticle = ({ data }) => {
  function createElems(props) {
    return (
      props.children !== undefined &&
      props.children.map((item, index) => {
        if (item.tagName === "br") {
          return <br key={keyGenerate(item.tagName, index)} />
        }
        if (item.tagName === "h1") {
          return (
            <h1
              key={keyGenerate(item.tagName, index)}
              className={item.className}
            >
              {item.text}
              {item.children && createElems(item)}
            </h1>
          )
        }
        if (item.tagName === "h2") {
          return (
            <FestSubtitle key={keyGenerate(item.tagName, index)} data={item} />
          )
        }
        if (item.tagName === "h3") {
          return (
            <FestArticleTitle
              key={keyGenerate(item.tagName, index)}
              data={item}
            />
          )
        }
        if (item.tagName === "h4") {
          return (
            <h4
              key={keyGenerate(item.tagName, index)}
              className={item.className}
            >
              {item.text}
              {item.children && createElems(item)}
            </h4>
          )
        }
        if (item.tagName === "p") {
          return (
            <FestParagraph key={keyGenerate(item.tagName, index)} data={item} />
          )
        }
        if (item.tagName === "nav") {
          return (
            <nav
              key={keyGenerate(item.tagName, index)}
              className={item.className}
            >
              {item.text}
              {item.children && createElems(item)}
            </nav>
          )
        }
        if (item.tagName === "a") {
          return (
            <a
              key={keyGenerate(item.tagName, index)}
              className={item.className}
              href={item.href}
              title={item.title}
              target={item.target}
            >
              {item.text}
              {item.children && createElems(item)}
            </a>
          )
        }
        if (item.tagName === "link") {
          return (
            <FestLinks key={keyGenerate(item.tagName, index)} data={item} />
          )
        }
        if (item.tagName === "div") {
          return (
            <div
              key={keyGenerate(item.tagName, index)}
              className={item.className}
            >
              {item.text}
              {item.children && createElems(item)}
            </div>
          )
        }
        if (item.tagName === "ul") {
          return (
            <ul
              key={keyGenerate(item.tagName, index)}
              className={item.className}
            >
              {item.text}
              {item.children && createElems(item)}
            </ul>
          )
        }
        if (item.tagName === "li") {
          return (
            <li
              key={keyGenerate(item.tagName, index)}
              className={item.className}
            >
              {item.text}
              {item.children && createElems(item)}
            </li>
          )
        }
        if (item.tagName === "img") {
          return (
            <>
              {isBrowser() && (
                <img
                  key={keyGenerate(item.tagName, index)}
                  className={item.className}
                  src={`/images/festivals/${checkUrl()}/` + item.src}
                  alt={item.alt}
                />
              )}
            </>
          )
        }
        if (item.tagName === "audio") {
          return (
            <>
              {isBrowser() && (
                <audio
                  key={keyGenerate(item.tagName, index)}
                  className={item.className}
                  src={`/images/festivals/${checkUrl()}/` + item.src}
                  alt={item.alt}
                  type={item.type}
                  controls
                >
                  {item.children && createElems(item)}
                </audio>
              )}
            </> // nie genruje tesktu BŁĄD !!!//
          )
        }
        if (item.tagName === "figure") {
          return (
            <FestFigure key={keyGenerate(item.tagName, index)} data={item} />
          )
        }
        if (item.tagName === "figcaption") {
          return (
            <figcaption
              key={keyGenerate(item.tagName, index)}
              className={item.className}
            >
              {item.text}
              {item.children && createElems(item)}
            </figcaption>
          )
        }
        if (item.tagName === "code") {
          return (
            <code
              key={keyGenerate(item.tagName, index)}
              className={item.className}
            >
              {item.text}
            </code>
          )
        }
        if (item.tagName === "i") {
          return (
            <i
              key={keyGenerate(item.tagName, index)}
              className={item.className}
            >
              {item.text}
              {item.children && createElems(item)}
            </i>
          )
        }
        if (item.tagName === "b") {
          return (
            <b
              key={keyGenerate(item.tagName, index)}
              className={item.className}
            >
              {item.text}
              {item.children && createElems(item)}
            </b>
          )
        }
        if (item.tagName === "blockquote") {
          return (
            <blockquote
              key={keyGenerate(item.tagName, index)}
              className={item.className}
              cite={item.className || ""}
            >
              {item.text}
              {item.children && createElems(item)}
            </blockquote>
          )
        }
        if (item.tagName === "q") {
          return (
            <q
              key={keyGenerate(item.tagName, index)}
              className={item.className}
              cite={item.className || ""}
            >
              {item.text}
              {item.children && createElems(item)}
            </q>
          )
        }
        if (item.tagName === "line") {
          return (
            <div
              key={keyGenerate(item.tagName, index)}
              className={item.className}
              cite={item.className || ""}
            ></div>
          )
        }
        if (item.docLink !== undefined)
          return (
            <DocLink
              key={keyGenerate(item.docLink, index)}
              collection={item.docLink}
              location={item.location}
            />
          )
        if (item.tagName === undefined && item.text !== undefined) {
          return item.text
        }
      })
    )
  }
  return (
    <article className={data.className} id={data.id}>
      {createElems(data)}
    </article>
  )
}

export default FestArticle
