import React from "react"
import { Link } from "gatsby"

import "./HeroBtn.scss"

const HeroBtn = ({link, text, special}) => (
  <Link className={"hero-btn__box " + special} to={link}>
    <button className="hero__btn">{text}</button>
  </Link>
)

export default HeroBtn