import React from "react"
import "./FestFooter.scss"
import { langVersion, checkUrl } from "../../accessors"
import FestFooterSocials from "./FestFooterSocials"
import FestFooterParagraph from "./FestFooterParagraph"
import KosmoBackTopBtn from "./KosmoBackTopBtn"
import { Link } from "gatsby"

const FestFooter = ({ socials, footer }) => {
  return (
    <footer className="fest__page-footer">
      <section className="fest__footer-info">
        <article className="fest__article">
          <img
            className="fest__footer-logo"
            src={"/images/icons/between_2020_white.png"}
            alt={"Logo of between.pomiędzy"}
          ></img>
          <FestFooterParagraph text={footer.Footer1Text1} />
          <FestFooterParagraph text={footer.Footer1Text2} />
        </article>
        <article className="fest__article">
          <Link
            className="fest__footer-link"
            to={langVersion(
              `/festivals/${checkUrl()}/contact`,
              `/en/festivals/${checkUrl()}/contact`
            )}
            title={langVersion(
              `Idź do strony kontaktowej`,
              `Go to Contact page`
            )}
          >
            {footer.FooterLink1}
          </Link>
          <Link
            className="fest__footer-link"
            to={langVersion(
              `/festivals/${checkUrl()}/about`,
              `/en/festivals/${checkUrl()}/about`
            )}
            title={langVersion(`Idź do strony O Nas`, `Go to About Us page`)}
          >
            {footer.FooterLink2}
          </Link>
        </article>
        <article className="fest__article">
          <FestFooterParagraph text={footer.Footer3Text1} />
          <div className="fest__footer-socials">
            {socials &&
              socials.map((social, index) => (
                <FestFooterSocials key={social.name} data={social} />
              ))}
          </div>
        </article>
      </section>
      <section className="fest__footer-authors">
        <FestFooterParagraph text={footer.FooterAuthor} />
      </section>
      <KosmoBackTopBtn />
    </footer>
  )
}

export default FestFooter
