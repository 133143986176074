import React from "react"
import { keyGenerate, checkUrl, isBrowser } from "../../accessors"
import { colorVersion } from "../../accessors/kosmopolis"
import { Link } from "gatsby"

const FestLinks = ({ data }) => {
  function createElems(props) {
    return (
      props.children !== undefined &&
      props.children.map((item, index) => {
        if (item.text && !item.tagName) {
          return item.text
        }
        if (item.tagName === "i") {
          return (
            <i
              key={keyGenerate(item.tagName, index)}
              className={item.className}
              to={item.href}
              title={item.title}
            >
              {item.text}
              {item.children && createElems(item)}
            </i>
          )
        }
        if (item.tagName === "div") {
          return (
            <div
              key={keyGenerate(item.tagName, index)}
              className={
                item.program !== undefined
                  ? `${item.className} ${colorVersion(item.program, item.elem)}`
                  : item.className
              }
            >
              {item.text}
              {item.children && createElems(item)}
            </div>
          )
        }
        if (item.tagName === "img") {
          return (
            isBrowser() && (
              <img
                key={keyGenerate(item.tagName, index)}
                className={item.className}
                src={`/images/festivals/${checkUrl()}/` + item.src}
                alt={item.alt}
              />
            )
          )
        }
        if (item.tagName === "h4") {
          return (
            <h4
              key={keyGenerate(item.tagName, index)}
              className={item.className}
            >
              {item.text}
              {item.children && createElems(item)}
            </h4>
          )
        }
        if (item.tagName === "h2") {
          return (
            <h2
              key={keyGenerate(item.tagName, index)}
              className={item.className}
            >
              {item.text}
              {item.children && createElems(item)}
            </h2>
          )
        }
      })
    )
  }
  return (
    <Link
      className={data.className}
      to={data.href}
      title={data.title}
      key={keyGenerate(data.title, Math.random() * 100)}
    >
      {data.text ? data.text : createElems(data)}
    </Link>
  )
}

export default FestLinks
