import React from "react"

import Fest13MainTemplate from "../../../components/FestPages/13Festival/13FestMainTemplate"
import ComingSoon from "../../../components/Service/ComingSoon"
// import FestSection from "../../../components/FestPages/FestSection"
// const contact = require("../../../content/festivals/13-festival/about/about.json")

const IndexPage = () => (
  <Fest13MainTemplate>
    <ComingSoon />
  </Fest13MainTemplate>
)

export default IndexPage
