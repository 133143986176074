const PINK = "fest__background--pink"
const BLUE = "fest__background--blue"
const GREY = "fest__background--grey"
const RED = "fest__background--red"
const FOTO = "fest__background--transparent"

const arr1 = [RED, FOTO, GREY, BLUE, FOTO, BLUE, PINK]
const arr2 = [BLUE, GREY, BLUE, FOTO, BLUE, PINK, FOTO]
const arr3 = [FOTO, BLUE, PINK, BLUE, GREY, FOTO, BLUE]

export const colorVersion = (nmbr, elem) => {
  let color
  nmbr === "1" && (color = arr1[elem - 1])
  nmbr === "2" && (color = arr2[elem - 1])
  nmbr === "3" && (color = arr3[elem - 1])
  return color
}
