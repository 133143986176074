import React from "react"
import "../components/FestPages/FestContainers.scss"
import KosmoHeader from "../components/FestPages/KosmoHeader"
import FestSection from "../components/FestPages/FestSection"
import FestFooter from "../components/FestPages/FestFooter"

const KosmopolisPage = ({ pageContext: { data } }) => {
  const chapter = require(`../content/${
    data.lang !== undefined ? data.lang : ""
  }festivals/kosmopolis/${data.folder}/${data.page}.json`)
  const socials = require(`../content/${
    data.lang !== undefined ? data.lang : ""
  }festivals/kosmopolis/socials.json`)
  const footer = require(`../content/${
    data.lang !== undefined ? data.lang : ""
  }festivals/kosmopolis/footer.json`)
  return (
    <>
      <KosmoHeader page={data.page} />
      <main className="fest-main">
        <FestSection data={chapter} />
      </main>
      <FestFooter socials={socials} footer={footer} />
    </>
  )
}

export default KosmopolisPage
