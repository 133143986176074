import React, { useState } from "react"
import "./Header.scss"
import { langVersion, switchLang } from "../../accessors"
import { HEADER_TEXTS } from "../../constans"
import HeaderLink from "./HeaderLink"
import HeaderLanguageBtn from "./HeaderLanguageBtn"
import HeaderBrand from "./HeaderBrand"
import ContrastBtn from "./ContrastBtn"
import HamburgerBtn from "./HamburgerBtn"

//const pageUrl = window.location.host;

const Header = props => {
  const [nav, navToggle] = useState("")
  const [hmbr, hmbrToggle] = useState("")
  const [navlink, navlinkToggle] = useState("")
  const names = [
    "about",
    "contact",
    "cooperation",
    "almanac",
    "research",
    "festivals",
  ]

  const activePage = pl => {
    const namesSearch = collection => {
      let name = false

      names.map(i => {
        collection.includes(i) && (name = true)
      })
      return name
    }

    if (typeof window !== `undefined`) {
      const pageUrl = window.location.pathname.split("/")

      if (pageUrl.includes(pl) === true) {
        return "nav__link nav__link--active"
      } else if (pl === "index") {
        if (namesSearch(pageUrl) !== true) {
          return "nav__link nav__link--active"
        } else {
          return "nav__link"
        }
      } else {
        return "nav__link"
      }
    }
  }

  const navToggler = () => {
    nav === "" ? navToggle(" navbar--opened") : navToggle("")
    hmbr === "" ? hmbrToggle(" hamburger--added") : hmbrToggle("")
    navlink === "" ? navlinkToggle(" nav__link--open") : navlinkToggle("")
  }

  const navTogglerResize = () => {
    if (typeof window !== `undefined`) {
      window.addEventListener("resize", () => {
        nav === " navbar--opened" && navToggle("")
        hmbr === " hamburger--added" && hmbrToggle("")
        navlink === " nav__link--open" && navlinkToggle("")
      })
    }
  }

  navTogglerResize()

  switchLang()
  return (
    <header className="page__header">
      {typeof window !== `undefined` ? (
        <>
          <HeaderBrand link={langVersion("/", "/en/")} />
          <nav className={"navbar" + nav}>
            <HeaderLink
              name={activePage("index") + navlink}
              link={langVersion("/", "/en/")}
              text={langVersion(HEADER_TEXTS[0][0], HEADER_TEXTS[0][1])}
            />
            <HeaderLink
              name={activePage("about") + navlink}
              link={langVersion("/about", "/en/about")}
              text={langVersion(HEADER_TEXTS[1][0], HEADER_TEXTS[1][1])}
            />
            <HeaderLink
              name={activePage("almanac") + navlink}
              link={langVersion("/almanac", "/en/almanac")}
              text={langVersion(HEADER_TEXTS[2][0], HEADER_TEXTS[2][1])}
            />
            <HeaderLink
              name={activePage("cooperation") + navlink}
              link={langVersion("/cooperation", "/en/cooperation")}
              text={langVersion(HEADER_TEXTS[3][0], HEADER_TEXTS[3][1])}
            />
            <HeaderLink
              name={activePage("contact") + navlink}
              link={langVersion("/contact", "/en/contact")}
              text={langVersion(HEADER_TEXTS[4][0], HEADER_TEXTS[4][1])}
            />
          </nav>
          <section className="switch-buttons">
            <HeaderLanguageBtn
              link={switchLang()}
              lang={langVersion("Eng", "Pl")}
            />
            <ContrastBtn />
            <button onClick={navToggler} className={"hamburger" + hmbr}>
              <HamburgerBtn />
            </button>
          </section>
        </>
      ) : (
        <div>Header is loading...</div>
      )}
    </header>
  )
}

export default Header
