import React from "react"
import { Link } from "gatsby"
import { langVersion } from "../../accessors"

const ErrorInfo = () => (
  <section className="error-info">
    <h1 className="page__title page__title--danger">
      {langVersion("błąd", "error")} 404
    </h1>
    <h2 className="page__title">
      {langVersion("Strona nie znaleziona", "Page not found")}
    </h2>
    <p className="article__text">&nbsp;</p>
    <p className="article__text">
      <Link className="text--link" to={langVersion("/", "/en/")}>
        {langVersion("Przejdź do Between.Pomiędzy", "Go to Between.Pomiędzy")}
      </Link>
    </p>
  </section>
)

export default ErrorInfo
