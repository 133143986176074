import React from "react"
import Head from "../../../components/Head"

import KosmoHeader from "../../../components/FestPages/KosmoHeader"
import FestSection from "../../../components/FestPages/FestSection"
import FestFooter from "../../../components/FestPages/FestFooter"
const about = require("../../../content/festivals/kosmopolis/about/about.json")
const socials = require("../../../content/festivals/kosmopolis/socials.json")
const footer = require("../../../content/festivals/kosmopolis/footer.json")
const PageTitle = "About"

const IndexPage = () => (
  <>
    <Head pageTitle={"Between.Pomiędzy | Kosmopolis.2020 | " + PageTitle} />
    <KosmoHeader title={PageTitle} />

    <main className="main">
      <FestSection data={about} />
    </main>

    <FestFooter socials={socials} footer={footer} />
  </>
)

export default IndexPage
