import React, { useState } from "react"
import Head from "../../Head"

import "../FestTitles.scss"
import "../FestContainers.scss"
import Fest13Header from "./13FestHeader"
import FestFooter from "../FestFooter"
import { takeUrl, langVersion, isBrowser } from "../../../accessors"
const socials = require("../../../content/festivals/common/socials.json")
const footerPL = require("../../../content/festivals/13-festival/footer.json")
const footerEN = require("../../../content/en/festivals/13-festival/footer.json")

const PageTitle = "Festiwal Rozproszony"

const Fest13MainTemplate = ({ children }) => {
  const [pageSubtitle, setPageSubtitle] = useState(
    takeUrl() !== "13-festival" && takeUrl()
  )
  return (
    <>
      <Head
        pageTitle={`Between.Pomiędzy | ${
          isBrowser() &&
          langVersion("Festiwal Rozproszony", "Dispersed Festival")
        }${pageSubtitle ? " | " + pageSubtitle : ""}`}
      />
      <Fest13Header title={PageTitle} />
      <main className="fest__main">{children}</main>
      {/* <FestFooter socials={socials} footer={footerPL} /> */}
      <FestFooter
        socials={socials}
        footer={isBrowser() && langVersion(footerPL, footerEN)}
      />
    </>
  )
}

export default Fest13MainTemplate
