import React, { useState } from "react"
import { keyGenerate, checkUrl, isBrowser } from "../../accessors"
import FestParagraph from "./FestParagraph"
import FestLinks from "./FestLinks"
import "./FestContainers.scss"

const FestFigure = ({ data }) => {
  const [bigImg, setBigImg] = useState(false)

  function createElems(props) {
    return (
      props.children !== undefined &&
      props.children.map((item, index) => {
        if (item.tagName === "br") {
          return (
            <br key={keyGenerate(item.tagName, index + Math.random() * 100)} />
          )
        }
        if (item.tagName === "p") {
          return (
            <FestParagraph
              key={keyGenerate(item.tagName, index + Math.random() * 100)}
              data={item}
            />
          )
        }
        if (item.tagName === "nav") {
          return (
            <nav
              key={keyGenerate(item.tagName, index + Math.random() * 100)}
              className={item.className}
            >
              {item.text}
              {item.children && createElems(item)}
            </nav>
          )
        }
        if (item.tagName === "a") {
          return (
            <a
              key={keyGenerate(item.tagName, index + Math.random() * 100)}
              className={item.className}
              href={item.href}
              title={item.title}
              target={item.target}
            >
              {item.text}
              {item.children && createElems(item)}
            </a>
          )
        }
        if (item.tagName === "link") {
          return (
            <FestLinks
              key={keyGenerate(item.tagName, index + Math.random() * 100)}
              data={item}
            />
          )
        }
        if (item.tagName === "img") {
          return (
            isBrowser() && (
              <img
                key={keyGenerate(item.tagName, index + Math.random() * 100)}
                className={item.className}
                src={`/images/festivals/${checkUrl()}/` + item.src}
                alt={item.alt}
              />
            )
          )
        }
        if (item.tagName === "audio") {
          return (
            isBrowser() && (
              <audio
                key={keyGenerate(item.tagName, index + Math.random() * 100)}
                className={item.className}
                src={`/images/festivals/${checkUrl()}/` + item.src}
                alt={item.alt}
                type={item.type}
                controls
              >
                {item.children && createElems(item)}
              </audio>
            )
          )
        }
        if (item.tagName === "figcaption") {
          return (
            <figcaption
              key={keyGenerate(item.tagName, index + Math.random() * 100)}
              className={item.className}
            >
              {item.text}
              {item.children && createElems(item)}
            </figcaption>
          )
        }
        if (item.tagName === "code") {
          return (
            <code
              key={keyGenerate(item.tagName, index + Math.random() * 100)}
              className={item.className}
            >
              {item.text}
            </code>
          )
        }
        if (item.tagName === "i") {
          return (
            <i
              key={keyGenerate(item.tagName, index + Math.random() * 100)}
              className={item.className}
            >
              {item.text}
              {item.children && createElems(item)}
            </i>
          )
        }
        if (item.tagName === undefined && item.text !== undefined) {
          return item.text
        }
      })
    )
  }
  return (
    <figure
      className={`${data.className} ${bigImg ? "fest__big-img" : ""}`}
      onClick={() => setBigImg(!bigImg)}
      id={data.id}
      key={keyGenerate(data.id, Math.random() * 200)}
    >
      {createElems(data)}
    </figure>
  )
}

export default FestFigure
