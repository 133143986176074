import React from "react"
import Head from '../components/Head';

import Header from "../components/Headers/Header";
import Footer from "../components/Footers/Footer";
import PageHeader from "../components/PageHeaders/PageHeader";
import Elems from "../templates/elems";
import { PAGE_TITLE } from '../content/contact/constans';

import { data } from '../content/contact/contact.json';
const PageTitle = PAGE_TITLE[0];


const IndexPage = () => {

  //const takeData = () => {dataTXT.fetch}
  return <>
    <Head pageTitle={"Between.Pomiędzy | " + PageTitle} />
    <Header title={PageTitle} />

    <main id="contact-page" className="main">
      <PageHeader pageTitle={PageTitle}/>
      <Elems data={data}/>
    </main>

    <Footer />

  </>
}

export default IndexPage
