import React from "react";


const ProtectedMain = () => {



  return (
    <>

      <h1>Protected</h1>
      <h2>Witaj</h2>
    </>
  )
}

export default ProtectedMain;