import React from "react"
import "../components/FestPages/FestContainers.scss"
import Fest15Header from "../components/FestPages/15Festival/15FestHeader"
import FestSection from "../components/FestPages/FestSection"
import Fest15Footer from "../components/FestPages/15Festival/15FestFooter"

const Fest15Page = ({ pageContext: { page, lang } }) => {
  // console.log(page)
  const chapter = require(`../content/${
    lang !== undefined ? lang : ""
  }theatre-literature-management/chapters/${page.title}.json`)
  const socials = require(`../content/${
    lang !== undefined ? lang : ""
  }theatre-literature-management/socials.json`)
  const footer = require(`../content/${
    lang !== undefined ? lang : ""
  }theatre-literature-management/footer.json`)
  return (
    <>
      <Fest15Header page={page} />
      <main className="fest-main">
        <FestSection data={chapter} />
      </main>
      <Fest15Footer socials={socials} footer={footer} />
    </>
  )
}

export default Fest15Page
