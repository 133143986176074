export const EDUCATION_2020 = {
  title: {
    pl: "Edycja 2020",
    en: "Edition 2020",
  },
  description: {
    pl: "Projekt edukacyjny",
    en: "Education project",
  },
  logo: "../../images/logos/2020-NCK_dofinans_kulturawsieci-rgb.jpg",
  bgPhoto: "../../images/photos/B1_mozaic.jpg",
  sections: [
    { title: "Wstęp", source: "A" },
    { title: "Konspekty", source: "B" },
    { title: "Film", source: "C" },
    { title: "Rozwiniecie", source: "D" },
    { title: "Webinaria", source: "E" },
    { title: "Podsumowanie", source: "F" },
  ],
}

export const EDUCATION_2021 = {
  title: {
    pl: "Edycja 2021",
    en: "Edition 2021",
  },
  description: {
    pl: "Projekt edukacyjny",
    en: "Education project",
  },
  logo: "",
  bgPhoto: "../../images/photos/education/2021/Between.Edukacja2021.small.jpg",
  sections: [
    { title: "Wstęp", source: "A" },
    { title: "Pakiet edukacyjny", source: "B" },
    { title: "Podsumowanie", source: "F" },
  ],
}
