import React from "react"

import Fest13MainTemplate from "../../../../components/FestPages/13Festival/13FestMainTemplate"
import FestSection from "../../../../components/FestPages/FestSection"
const contact = require("../../../../content/en/festivals/13-festival/contact/contact.json")

const IndexPage = () => (
  <Fest13MainTemplate>
    <FestSection data={contact} />
  </Fest13MainTemplate>
)

export default IndexPage
