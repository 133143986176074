import React from "react"
import Head from "../../../../components/Head"

import "../../../../components/FestPages/FestTitles.scss"
import "../../../../components/FestPages/FestContainers.scss"
import "../../../../components/FestPages/FestWelcome.scss"
import "../../../../components/FestPages/FestProgram.scss"
import "../../../../components/FestPages/FestCooperation.scss"
import Fest12Header from "../../../../components/FestPages/12FestHeader"
import Fest12Hero from "../../../../components/FestPages/12FestHero"
import FestSection from "../../../../components/FestPages/FestSection"
import FestFooter from "../../../../components/FestPages/FestFooter"
const welcome = require("../../../../content/en/festivals/12-festival/welcome.json")
const program = require("../../../../content/en/festivals/12-festival/program.json")
const socials = require("../../../../content/en/festivals/12-festival/socials.json")
const footer = require("../../../../content/en/festivals/12-festival/footer.json")

const PageTitle = "12 Festival"

const IndexPage = () => (
  <>
    <Head pageTitle={"Between.Pomiędzy | " + PageTitle} />
    <Fest12Header title={PageTitle} />

    <main className="fest__main">
      <Fest12Hero />
      <FestSection data={welcome} />
      <FestSection data={program} />
    </main>

    <FestFooter socials={socials} footer={footer} />
  </>
)

export default IndexPage
