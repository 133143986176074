import React from "react"
import { keyGenerate } from "../../accessors"
import { Link } from "gatsby"

const FestParagraph = ({ data }) => {
  function createElems(props) {
    return (
      props.children !== undefined &&
      props.children.map((item, index) => {
        if (item.text && !item.tagName) {
          return item.text
        }
        if (item.tagName === "a") {
          return (
            <a
              key={keyGenerate(item.tagName, index)}
              className={item.className}
              href={item.href}
              title={item.title}
              target={item.target}
            >
              {item.text}
              {item.children && createElems(item)}
            </a>
          )
        }
        if (item.tagName === "link") {
          return (
            <Link
              key={keyGenerate(item.tagName, index)}
              className={item.className}
              to={item.href}
              title={item.title}
            >
              {item.text}
              {item.children && createElems(item)}
            </Link>
          )
        }
        if (item.tagName === "b") {
          return (
            <b
              key={keyGenerate(item.tagName, index)}
              className={item.className}
            >
              {item.text ? item.text : createElems(item)}
            </b>
          )
        }
        if (item.tagName === "strong") {
          return (
            <strong
              key={keyGenerate(item.tagName, index)}
              className={item.className}
            >
              {item.text}
              {item.children && createElems(item)}
            </strong>
          )
        }
        if (item.tagName === "i") {
          return (
            <i
              key={keyGenerate(item.tagName, index)}
              className={item.className}
            >
              {item.text ? item.text : createElems(item)}
            </i>
          )
        }
        if (item.tagName === "q") {
          return (
            <q
              key={keyGenerate(item.tagName, index)}
              className={item.className}
              cite={item.className || ""}
            >
              {item.text}
              {item.children && createElems(item)}
            </q>
          )
        }
        if (item.tagName === "br") {
          return <br key={keyGenerate(item.tagName, index)}></br>
        }
      })
    )
  }
  return (
    <p className={data.className}>
      {data.text ? data.text : createElems(data)}
    </p>
  )
}

export default FestParagraph
