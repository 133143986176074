import React from "react";
import Head from '../../components/Head';

import Header from "../../components/Headers/Header";
import Footer from "../../components/Footers/Footer";
import PageHeader from "../../components/PageHeaders/PageHeader";
import CommingSoon from "../../components/Service/ComingSoon";

import { PAGE_TITLE } from '../../content/about/constans';

const PageTitle = PAGE_TITLE[1];

const IndexPage = () => (
  <>
    <Head pageTitle={"Between.Pomiędzy | " + PageTitle} />
    <Header title={PageTitle} />

    <main className="main">
      <PageHeader pageTitle={PageTitle} />
      <CommingSoon/>
    </main>

    <Footer />
  </>
)

export default IndexPage