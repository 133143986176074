import React from "react"
import Head from "../../../../components/Head"

import KosmoHeader from "../../../../components/FestPages/KosmoHeader"
import FestSection from "../../../../components/FestPages/FestSection"
import FestFooter from "../../../../components/FestPages/FestFooter"
const contact = require("../../../../content/en/festivals/kosmopolis/contact/contact.json")
const socials = require("../../../../content/en/festivals/kosmopolis/socials.json")
const footer = require("../../../../content/en/festivals/kosmopolis/footer.json")
const PageTitle = "Contact"

const IndexPage = () => (
  <>
    <Head pageTitle={"Between.Pomiędzy | Kosmopolis.2020 | " + PageTitle} />
    <KosmoHeader title={PageTitle} />

    <main className="main">
      <FestSection data={contact} />
    </main>

    <FestFooter socials={socials} footer={footer} />
  </>
)

export default IndexPage
