import React from "react"
import Head from "../../../components/Head"

import Header from "../../../components/Headers/Header"
import Footer from "../../../components/Footers/Footer"
import "../../../styles/_layout.scss"
import PageHeader from "../../../components/PageHeaders/PageHeader"
import Elems from "../../../templates/elems"
import { ARCHIVE_TITLE } from "../../../content/news/constans"
import { data } from "../../../content/en/news/20230909/20230909.json"

const PageTitle = ARCHIVE_TITLE[1]

const IndexPage = () => (
  <>
    <Head pageTitle={"Between.Pomiędzy | " + PageTitle} />
    <Header title={PageTitle} />

    <main className="main">
      <PageHeader pageTitle={PageTitle} />
      <Elems data={data} />
    </main>

    <Footer />
  </>
)

export default IndexPage
