import React, { useState } from "react"
import { Link } from "gatsby"
import "../FestHeader.scss"
import "../FestLinks.scss"
import { langVersion, switchLang, keyGenerate } from "../../../accessors"
import { colorVersion } from "../../../accessors/12-festival"
import HeaderLanguageBtn from "../../Headers/HeaderLanguageBtn"
import HeaderBrand from "../../Headers/HeaderBrand"
import ContrastBtn from "../../Headers/ContrastBtn"
import HamburgerBtn from "../../Headers/HamburgerBtn"
const program = require("../../../content/theatre-literature-management/nav-program.json")
const programEn = require("../../../content/en/theatre-literature-management/nav-program.json")

const FestHeader = props => {
  const [nav, navToggle] = useState("")
  const [hmbr, hmbrToggle] = useState("")
  const [navlink, navlinkToggle] = useState("")
  // const names = ["start", "program", "cooperation", "about", "contact"]
  const names = ["start", "program"]
  const $15FestUrl = "/theatre-literature-management"

  const activePage = pl => {
    const namesSearch = collection => {
      let name = false

      names.map(i => {
        collection.includes(i) && (name = true)
      })
      return name
    }

    if (typeof window !== `undefined`) {
      const pageUrl = window.location.pathname.split("/")

      if (pageUrl.includes(pl) === true) {
        return "fest__nav-link fest__nav-link--active"
      } else if (pl === "index") {
        if (namesSearch(pageUrl) !== true) {
          return "fest__nav-link fest__nav-link--active"
        } else {
          return "fest__nav-link"
        }
      } else {
        return "fest__nav-link"
      }
    }
  }

  const navToggler = () => {
    nav === "" ? navToggle(" fest__navbar--opened") : navToggle("")
    hmbr === "" ? hmbrToggle(" fest__hamburger--added") : hmbrToggle("")
    navlink === "" ? navlinkToggle(" fest__nav-link--open") : navlinkToggle("")
  }

  const navTogglerResize = () => {
    if (typeof window !== `undefined`) {
      window.addEventListener("resize", () => {
        nav === " fest__navbar--opened" && navToggle("")
        hmbr === " fest__hamburger--added" && hmbrToggle("")
        navlink === " fest__nav-link--open" && navlinkToggle("")
      })
    }
  }

  navTogglerResize()
  switchLang()

  return (
    <header className="fest__page-header">
      {typeof window !== `undefined` ? (
        <>
          <HeaderBrand link={langVersion("/", "/en/")} />
          <nav className={"fest__navbar" + nav}>
            <Link
              className={activePage("start") + navlink}
              to={langVersion(`${$15FestUrl}`, `/en${$15FestUrl}`)}
            >
              {langVersion("Start", "Start")}
            </Link>

            <div className={"fest__nav-link fest__nav-links-more " + navlink}>
              {langVersion("Program", "Programme")}
              <ul className={"fest__links-more__box--singles"}>
                {langVersion(program, programEn).children.map(
                  (chapter, index) => {
                    return (
                      <Link
                        key={keyGenerate(chapter.text, index)}
                        className={
                          chapter.program !== undefined
                            ? `fest__link-single-chapters ${colorVersion(
                                chapter.program,
                                chapter.elem
                              )}`
                            : "fest__link-single-chapters "
                        }
                        to={langVersion(
                          `${$15FestUrl}/${chapter.href}`,
                          `/en${$15FestUrl}/${chapter.href}`
                        )}
                      >
                        {chapter.text}
                      </Link>
                    )
                  }
                )}
              </ul>
            </div>
            {/* <div className={"fest__nav-link fest__nav-links-more " + navlink}>
              {langVersion("Program", "Programme")}
              <ul className={"fest__links-more__box"}>
                {langVersion(program, programEn).children.map(
                  (folder, index) => {
                    return (
                      <li
                        key={keyGenerate(folder.text, index)}
                        className="fest__link-more"
                      >
                        {folder.text}
                        <ul className="fest__link-more-children__box">
                          {folder.children.map((item, itemIndex) => {
                            return (
                              <Link
                                key={keyGenerate(item.text, index)}
                                // className={"fest__link-more-child "}
                                className={
                                  item.program !== undefined
                                    ? `fest__link-more-child ${colorVersion(
                                        item.program,
                                        item.elem
                                      )}`
                                    : "fest__link-more-child "
                                }
                                as={Link}
                                to={langVersion(
                                  `${$12FestUrl}/${item.href}`,
                                  `/en${$12FestUrl}/${item.href}`
                                )}
                              >
                                {item.text}
                              </Link>
                            )
                          })}
                        </ul>
                      </li>
                    )
                  }
                )}
              </ul>
            </div>
            <Link
              className={activePage("cooperation") + navlink}
              to={langVersion(
                `${$12FestUrl}/${names[2]}`,
                `/en${$12FestUrl}/${names[2]}`
              )}
            >
              {langVersion("Współpraca", "Cooperation")}
            </Link>
            */}
            {/* <Link
              className={activePage("about") + navlink}
              to={langVersion(
                `${$14FestUrl}/${names[3]}`,
                `/en${$14FestUrl}/${names[3]}`
              )}
            >
              {langVersion("O nas", "About")}
            </Link>

            <Link
              className={activePage("contact") + navlink}
              to={langVersion(
                `${$14FestUrl}/${names[4]}`,
                `/en${$14FestUrl}/${names[4]}`
              )}
            >
              {langVersion("Kontakt", "Contact")}
            </Link> */}

            <div className={"fest__nav-link fest__nav-links-more " + navlink}>
              <span className="fest__nav-link--more-text-1">
                {langVersion("Więcej", "More")}
              </span>
              <span className="fest__nav-link--more-text-2">· · ·</span>
              <ul className={"fest__links-more__box"}>
                <Link
                  className={"fest__link-more " + navlink}
                  to={langVersion(`/`, `/en/`)}
                >
                  {langVersion("Strona główna", "Home")}
                </Link>
                <Link
                  className={"fest__link-more " + navlink}
                  to={langVersion(`/festivals`, `/en/festivals`)}
                >
                  {langVersion("Festiwale", "Festivals")}
                </Link>
                <Link
                  className={"fest__link-more " + navlink}
                  to={langVersion(`/about`, `/en/about`)}
                >
                  {langVersion("Fundacja", "Foundation")}
                </Link>
              </ul>
            </div>
          </nav>
          <section className="switch-buttons">
            <HeaderLanguageBtn
              link={switchLang()}
              lang={langVersion("Eng", "Pl")}
            />
            <ContrastBtn />
            <button onClick={navToggler} className={"hamburger" + hmbr}>
              <HamburgerBtn />
            </button>
          </section>
        </>
      ) : (
        <div>Header is loading...</div>
      )}
    </header>
  )
}

export default FestHeader
