export const isBrowser = () => typeof window !== "undefined"

export const langVersion = (pl, en) => {
  if (typeof window !== `undefined`) {
    const pageLang = window.location.pathname.split("/")
    if (pageLang.includes("en") === true) {
      return en
    }
    if (pageLang.includes("en") === false) {
      return pl
    }
  }
}

export const switchLang = () => {
  if (typeof window !== `undefined`) {
    const pageLang = window.location.pathname.split("/")
    let index

    if (pageLang.includes("en") !== true) {
      index = 0
      let newAddress = pageLang
      newAddress[0] = "en"
      newAddress = newAddress.map(index => "/" + index)
      return newAddress.join("")
    }

    if (pageLang.includes("en") === true) {
      index = pageLang.indexOf("en")
      let newAddress = pageLang.slice(index + 1)
      newAddress = newAddress.map(index => "/" + index)
      return newAddress.join("")
    }
  }
}

export const takeUrl = () => {
  if (typeof window !== `undefined`) {
    let url = Array.from(window.location.pathname.split("/"))
    url[url.length - 1] === "" && url.pop()
    return url[url.length - 1]
  }
}

export const keyGenerate = (key, index) => {
  let newKey = Math.ceil(Math.random() * 999)
  return typeof key === "string" ? index + key : index + "_" + newKey
}

export const checkUrl = () => {
  if (typeof window !== `undefined`) {
    const festUrl = window.location.pathname.split("/")
    let index
    festUrl.find(index => index === "kosmopolis") && (index = "kosmopolis")
    festUrl.find(index => index === "12-festival") && (index = "12-festival")
    festUrl.find(index => index === "13-festival") && (index = "13-festival")
    festUrl.find(index => index === "14-festival") && (index = "14-festival")
    festUrl.find(index => index === "theatre-literature-management") && (index = "theatre-literature-management")
    return index
  }
}
