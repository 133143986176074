import React from "react"
import Head from "../../components/Head"

import Header from "../../components/Headers/Header"
import Footer from "../../components/Footers/Footer"
import "../../styles/_layout.scss"
import PageHeader from "../../components/PageHeaders/PageHeader"
import Elems from "../../templates/elems"
import { data } from "../../content/en/research/research.json"
import { PAGE_TITLE } from "../../content/research/constans"

const PageTitle = PAGE_TITLE[1]

const IndexPage = () => (
  <>
    <Head pageTitle={"Between.Pomiędzy | " + PageTitle} />
    <Header title={PageTitle} />

    <main className="main">
      <PageHeader pageTitle={PageTitle} />
      <Elems data={data} />
    </main>

    <Footer />
  </>
)

export default IndexPage
