import React from "react"
import { keyGenerate } from "../../accessors"
import FestArticle from "./FestArticle"
import "./FestContainers.scss"
import "./FestMedia.scss"
import "./FestLinks.scss"
import "./FestButtons.scss"
import "./FestImages.scss"

const FestSection = ({ data }) => {
  function createElems(props) {
    return (
      props.children !== undefined &&
      props.children.map((div, index) => {
        return (
          <div
            key={keyGenerate(div.sectionName, index)}
            className={div.sectionContainer}
          >
            {div.children !== undefined &&
              div.children.map((article, index) => {
                return (
                  <FestArticle
                    key={keyGenerate(article.className, index)}
                    data={article}
                  />
                )
              })}
          </div>
        )
      })
    )
  }
  return (
    <section
      id={data.sectionName}
      className={`fest__section ${data.className && data.className}`}
    >
      {createElems(data)}
    </section>
  )
}

export default FestSection
