import React from "react";
import { Link } from "gatsby";
import { langVersion } from "../../accessors";
import { removeUser } from "../../auth";
import { getUser } from "../../auth";
import { navigate } from "gatsby";

const AdmHeader = ({ profile }) => {

  const logOut = () => {
    removeUser();
    navigate('/admin');
    return null
  }

  return <nav className="adm-header">
    <Link className="adm-link" to="/">{langVersion("do Strony Głównej", "To Home Page")}</Link>
    {profile === 'private' && <button className="adm-link" onClick={logOut}>{langVersion("Wyloguj ", "Log Out ") +  getUser()}</button>}
  </nav>
}

export default AdmHeader;