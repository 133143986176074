import React from "react"
import { Link } from "gatsby"

import "./HeaderLink.scss"

const HeaderLink = ({ name, link, text }) => (
  <Link className={name} to={link}>
    {text}
  </Link>
)

export default HeaderLink
