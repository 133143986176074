import React from "react"

import "./FestivalChapters.scss"
import "./FestTitles.scss"
import {
  FESTIVALS_CHAPT1,
  FESTIVALS_CHAPT2,
  FESTIVALS_CHAPT3,
  FESTIVALS_CHAPT4,
} from "../../content/festivals/constans"
import {
  FESTIVALS_CHAPT1_DESCR,
  FESTIVALS_CHAPT2_DESCR,
  FESTIVALS_CHAPT3_DESCR,
  FESTIVALS_CHAPT4_DESCR,
} from "../../content/festivals/constans"
import bgPhoto1 from "../../images/photos/hero-small.jpg"
import bgPhoto2 from "../../images/photos/12-festival-small.jpg"
import bgPhoto3 from "../../images/photos/13-festival-small.jpg"
import bgPhoto4 from "../../images/photos/14-festival-small.jpg"
import { langVersion } from "../../accessors"
import ChapterLink from "../Index/ChapterLink"

const FestivalChapters = () => {
  return (
    <section id="chapters" className="festivals__chapters">
      <ChapterLink
        url={langVersion("/festivals/14-festival", "/en/festivals/14-festival")}
        title={langVersion(FESTIVALS_CHAPT4[0], FESTIVALS_CHAPT4[1])}
        text={langVersion(FESTIVALS_CHAPT4_DESCR[0], FESTIVALS_CHAPT4_DESCR[1])}
        img={bgPhoto4}
        attentionMark
      />
      <ChapterLink
        url={langVersion("/festivals/13-festival", "/en/festivals/13-festival")}
        title={langVersion(FESTIVALS_CHAPT3[0], FESTIVALS_CHAPT3[1])}
        text={langVersion(FESTIVALS_CHAPT3_DESCR[0], FESTIVALS_CHAPT3_DESCR[1])}
        img={bgPhoto3}
      />
      <ChapterLink
        url={langVersion("/festivals/12-festival", "/en/festivals/12-festival")}
        title={langVersion(FESTIVALS_CHAPT2[0], FESTIVALS_CHAPT2[1])}
        text={langVersion(FESTIVALS_CHAPT2_DESCR[0], FESTIVALS_CHAPT2_DESCR[1])}
        img={bgPhoto2}
      />
      <ChapterLink
        url={langVersion("/festivals/kosmopolis", "/en/festivals/kosmopolis")}
        title={langVersion(FESTIVALS_CHAPT1[0], FESTIVALS_CHAPT1[1])}
        text={langVersion(FESTIVALS_CHAPT1_DESCR[0], FESTIVALS_CHAPT1_DESCR[1])}
        img={bgPhoto1}
      />
    </section>
  )
}

export default FestivalChapters
